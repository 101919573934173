<template>
  <ValidationObserver @submit="addNewResult">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>
          <span v-if="!edit">Add Subject Exam Result</span>
          <span v-else>Edit Subject Exam Result</span>
        </span>
      </template>
      <template v-slot>
        <div>
          <InputFieldWrapper v-if="!currentSectionScope && !edit">
            <UiSingleSelect
              v-model="selectedSection"
              title="Section"
              :options="sectionList"
              label="title"
              reduce="id"
              class="flex-1"
              rules="required"
              @change="fetchSubjectsList"
              @deSelect="fetchSubjectsList"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputBox
              v-model="examData.title"
              type="text"
              name="TITLE"
              title="TITLE"
              label="TITLE"
              placeholder="TITLE"
              :class="$style.widthItems"
              class="flex-1"
              rules="required"
            />
            <InputBox
              v-model="examData.description"
              type="text"
              name="Description"
              title="Description"
              label="Description"
              placeholder="Description"
              :class="$style.widthItems"
              class="flex-1"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <UiSingleSelect
              v-model="examData.subject_id"
              title="SUBJECT"
              rules="required"
              :label="'title'"
              :reduce="'id'"
              :disabled="!!edit"
              :options="subjectsList"
              :class="$style.widthItems"
              class="flex-1"
              @change="getExamsList(examData.subject_id)"
            />
            <UiSingleSelect
              v-model="examData.non_gpa_grading_id"
              title="GRADING_STRUCTURE"
              rules="required"
              label="title"
              :reduce="'id'"
              :options="nonGPAGradingStructureList"
              :class="$style.widthItems"
              class="flex-1"
              @change="updatePassingPercentageHandler(examData.non_gpa_grading_id)"
              @deSelect="updatePassingPercentageHandler()"
            />
          </InputFieldWrapper>
          <div class="flex flex-col sm:flex-row gap-2 text-base" text-base>
            <div>
              <span v-i18n="placeholder">PASS_PER</span>
              :
            </div>
            <div>{{ examData.passing_percentage || GENERAL_CONSTANT.AMOUNT_ZERO }}%</div>
          </div>
          <Divider v-if="!edit" />
          <!-- Headers -->
          <div v-if="!edit">
            <div class="flex justify-end mb-5">
              <UIButton class="px-5" @click="addNewGradeItem">Add New Exams</UIButton>
            </div>
            <div>
              <div
                v-for="(exam, index) in examList"
                :key="index"
                class="flex flex-col sm:flex-row gap-4 lg:gap-5"
              >
                <InputFieldWrapper>
                  <UiSingleSelect
                    v-model="exam['subject_exam_schedule_id']"
                    title="Exam"
                    rules="required"
                    :dynamic-title="true"
                    :name="`EXAM + ${index}`"
                    label="title"
                    reduce="id"
                    :options="subjectsExamList"
                    :class="$style.widthItems"
                    class="flex-1"
                    @change="addTotalMarksInExam(exam.subject_exam_schedule_id, index)"
                  />
                  <InputBox
                    v-model.number="exam.total_marks"
                    type="text"
                    :name="`TOTAL_MARKS + ${index}`"
                    title="Total Marks"
                    label="Total Marks"
                    :is-not-translated="true"
                    :placeholder="`${exam.total_marks ? exam.total_marks : 0}`"
                    :disabled="true"
                    class="flex-1"
                  />
                  <InputBox
                    v-model.number="exam['weightage']"
                    type="text"
                    title="Weightage"
                    :is-not-translated="true"
                    :name="`WEIGHTAGE + ${index}`"
                    label="Weightage"
                    placeholder="Weightage"
                    class="flex-1"
                    rules="numeric|required"
                  />
                </InputFieldWrapper>
                <InputFieldWrapper>
                  <span v-if="index" class="sm:self-center self-end" @click="removeExamItem(index)">
                    <icon
                      class="icon cursor-pointer"
                      width="12"
                      height="12"
                      color="mahroon-strong"
                      icon="cross"
                    />
                  </span>
                </InputFieldWrapper>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>
              {{ edit ? 'Update' : 'Save' }}
            </span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { mapActions, mapState } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import Divider from '@src/components/Divider.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import icon from '@components/icons/icon.vue'
import { objectDeepCopy } from '@utils/generalUtil'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import GENERAL_CONSTANT from '@src/constants/general-constants'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { Form as ValidationObserver } from 'vee-validate'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    InputBox,
    ValidationObserver,
    Loader,
    Divider,
    UIButton,
    icon,
    UiSingleSelect,
    InputFieldWrapper,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['toggle'],
  data() {
    return {
      GENERAL_CONSTANT,
      placeholder: 'placeholder',
      newExam: { subject_exam_schedule_id: '', weightage: '' },
      examData: {
        title: '',
        description: '',
        subject_id: '',
        non_gpa_grading_id: '',
        passing_percentage: '',
      },
      examList: [{ subject_exam_schedule_id: '', weightage: '' }],
      isLoading: false,
      subjectsList: [],
      subjectsExamList: [],
      nonGPAGradingStructureList: [],
      totalMarks: 0,
      selectedSection: null,
    }
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope,
      sectionList: (state) => state.section.sectionList,
    }),
  },
  watch: {
    examList: {
      deep: true,
      handler(list) {
        this.totalMarks = 0
        list.forEach((exam) => {
          if (exam.total_marks) this.totalMarks += (exam.total_marks / 100) * exam.weightage
        })
      },
    },
  },

  created() {
    if (this.edit) this.setFormDate()
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      Promise.all([this.getNonGPAStructureList(), this.classSubjects()]).then((response) => {
        let [structureListResponse] = response[0]
        this.nonGPAGradingStructureList = structureListResponse?.data.records || []
        this.subjectsList = response[1]?.data?.records
      })
      this.filterSectionsList()
    },

    fetchSubjectsList() {
      this.examData.subject_id = ''
      this.subjectsExamList = []
      this.examList = [{ subject_exam_schedule_id: '', weightage: '' }]
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.selectedSection),
        },
      }
      this.classSubjects(payload).then((response) => {
        this.subjectsList = response.data.records
      })
    },

    getExamsList(id) {
      this.subjectsExamList = []
      let newExam = objectDeepCopy(this.newExam)
      this.examList = [{ ...newExam }]
      let filterQueries = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', id),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.selectedSection),
        },
      }

      let payload = filterQueries
      this.getExamsSubjectsList(payload).then((response) => {
        this.subjectsExamList = response.data.records
      })
    },
    addNewGradeItem() {
      let newExam = objectDeepCopy(this.newExam)
      this.examList.push(newExam)
    },
    updatePassingPercentageHandler(id) {
      const gradingStructure = this.nonGPAGradingStructureList.find(
        (structure) => structure.id === id,
      )
      this.examData.passing_percentage = gradingStructure ? gradingStructure.default_less_than : ''
    },
    addTotalMarksInExam(id, idx) {
      this.subjectsExamList.forEach((exam) => {
        if (exam.id === id) this.examList[idx]['total_marks'] = exam.total_marks
      })
    },
    removeExamItem(idx) {
      this.examList.splice(idx, 1)
    },
    handleClick(eventName) {
      if (eventName === 'close') this.$emit('toggle')
    },
    async addNewResult() {
      this.isLoading = true
      let payload = { structure: this.getPayload() }
      if (this.edit) payload.id = this.edit?.id
      this.addSubjectResult(payload).then(() => {
        this.$emit('toggle', true)
      })
      this.isLoading = false
    },
    getPayload() {
      if (this.selectedSection) this.examData.section_id = this.selectedSection
      return {
        ...this.examData,
        ...{ subject_exam_schedule_weightages: this.examList },
      }
    },
    setFormDate() {
      this.examData.title = this.edit?.title || ''
      this.examData.description = this.edit?.description || ''
      this.examData.non_gpa_grading_id = this.edit?.non_gpa_grading_id || ''
      this.examData.passing_percentage = this.edit?.passing_percentage || ''
      this.examData.subject_id = this.edit?.subject_id || ''
    },
    ...mapActions('exams', [
      'addNonGPAGradingStructure',
      'getExamsSubjectsList',
      'getNonGPAStructureList',
      'addSubjectResult',
    ]),
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('section', ['filterSectionsList']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
