<template>
  <div>
    <SubjectResultFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(resultsList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
          <TH v-show="showColumnInDataTable(filtersData, 'Section')" v-if="!currentSectionScope">
            Section
          </TH>
          <TH v-show="showColumnInDataTable(filtersData, 'Actions')">Actions</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(result, index) in resultsList" :key="index">
          <TD
            v-show="showColumnInDataTable(filtersData, 'Title', ['Title'])"
            @click="redirectToDetailsPage(result.id)"
          >
            <span class="text-primary-purple-600 cursor-pointer">
              {{ result.title || GENERAL_CONSTANT.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Subject', ['Subject'])">
            {{ result.subject.title || GENERAL_CONSTANT.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Summary')">
            {{ result.summary || GENERAL_CONSTANT.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Result Generated', ['Result Generated'])">
            {{ result.generated_at ? GENERAL_CONSTANT.GENERATED : GENERAL_CONSTANT.NOT_GENERATED }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Published')">
            {{ result.published_at ? GENERAL_CONSTANT.PUBLISHED : GENERAL_CONSTANT.NOT_PUBLISHED }}
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Section', ['Section'])"
            v-if="!currentSectionScope"
          >
            {{ result.section.title }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :idx="index"
              :current-user="result"
              :action-list="result.actionList"
              @action="typeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="resultsCounts > GENERAL_CONSTANT.RECORD_LIMIT"
      v-show="!isLoading"
      :record-limit="GENERAL_CONSTANT.RECORD_LIMIT"
      :max-range="paginationCounts(resultsCounts, GENERAL_CONSTANT.RECORD_LIMIT)"
      @filterRecord="filterRecords"
    />

    <SubjectResultModal
      v-if="isModalShow"
      :modal="isModalShow"
      :edit="currentSelectedResult"
      @toggle="toggleSubjectExamModal"
    />
    <UIConfirmationModal
      v-if="deleteModelShow"
      :modal="deleteModelShow"
      heading="DELETE_SUB_RESULT"
      message="Are you sure you want to delete "
      button-text="Delete"
      :name="currentSelectedResult.title"
      remaining-message="Result ?"
      :is-loading="isDeleteInProgress"
      @cancel="toogleDeleteModel"
      @confirm="deleteResultById"
    />
    <UIConfirmationModal
      v-if="generteResultModal"
      :modal="generteResultModal"
      heading="GENERATE_RESULT"
      message="Are you sure you want to generate result for "
      button-text="Confirm"
      :name="currentSelectedResult.title"
      @cancel="toggleGenerateResultModal"
      @confirm="generateResultForSubject"
    />
    <UIConfirmationModal
      v-if="reGenerteResultModal"
      :modal="reGenerteResultModal"
      heading="REGENERATE_RESULT"
      message="Are you sure you want to re generate result for "
      button-text="Confirm"
      :name="currentSelectedResult.title"
      @cancel="toggleReGenerateResultModal"
      @confirm="reGenerateResultForSubject"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import { paginationCounts } from '@src/components/BaseComponent/pagination.util.js'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import isEmpty from 'lodash/isEmpty'
import generalMixins from '@src/mixins/general-mixins.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import GENERAL_CONSTANT from '@src/constants/general-constants.js'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil'
import TableAction from '@components/TableAction.vue'
import subjectResultData from '@src/router/views/exam-planner/subjectResultData.json'
import fileMixin from '@src/mixins/file-mixins'
import SubjectResultModal from '@views/exam-planner/generate-subject-exam-result/AddAndEditSubjectResultModal.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants.js'
import SubjectResultFilter from '@/src/router/views/exam-planner/SubjectResultFilter.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    Pagination,
    Loader,
    NoRecordFound,
    TableAction,
    SubjectResultFilter,
    SubjectResultModal,
    UIConfirmationModal,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixins, fileMixin, scrollMixin],
  data() {
    return {
      GENERAL_CONSTANT,
      defaultSelectedColumns: subjectResultData.defaultSelectedColumns,
      tableHeaders: subjectResultData.tableHeaders,
      skipForFilterColumns: subjectResultData.skipForFilterColumns,
      isEmpty,
      filtersData: {},
      isLoading: false,
      currentSelectedResult: null,
      resultsList: [],
      resultsCounts: 0,
      isModalShow: false,
      deleteModelShow: false,
      generteResultModal: false,
      reGenerteResultModal: false,
      isDeleteInProgress: false,
      GENERAL_CONSTANTS,
    }
  },
  computed: {
    ...mapState('layout', ['currentSectionScope', 'currentClassScope']),
  },
  watch: {
    currentSectionScope: {
      handler() {
        this.filterRecords()
      },
    },
    currentClassScope: {
      handler(val) {
        if (val) this.filterRecords()
      },
    },
  },
  created() {
    this.setRightbarData()
    this.filterRecords()
  },
  methods: {
    showColumnInDataTable,
    paginationCounts,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecords()
    },
    deleteResultById() {
      this.deleteType(this.currentSelectedResult.id)
    },
    async filterRecords(range) {
      if (!this.currentClassScope) return

      this.isLoading = true
      const paginationLimit = paginationRangeHandler(range)
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload

      const [response, err] = await this.getSectionSubjectResultList(payload)
      this.isLoading = false
      if (!err) {
        this.resultsList = response?.data?.records
        this.resultsList.forEach(
          (result) => (result['actionList'] = this.getActionsList(result.generated_at)),
        )
        this.resultsCounts = response?.data?.meta?.total_records
        this.setRightbarData(this.resultsCounts)
      }
    },
    getActionsList(generatedAt) {
      let actions = [{ name: 'Edit' }, { name: 'Delete' }]
      generatedAt
        ? actions.unshift({ name: 'VIEW_RESULT' }, { name: 'REGENERATE_RESULT' })
        : actions.unshift({ name: 'GENERATE_RESULT' })
      return actions
    },
    toggleSubjectExamModal(payload) {
      this.isModalShow = !this.isModalShow
      if (!this.isModalShow) this.currentSelectedResult = null
      if (payload) this.filterRecords()
    },
    typeAction(action, currentType, idx) {
      this.currentSelectedResult = currentType
      switch (action) {
        case 'Edit':
          this.toggleSubjectExamModal()
          break
        case 'Delete':
          this.toogleDeleteModel()
          break
        case 'GENERATE_RESULT':
          this.toggleGenerateResultModal()
          break
        case 'REGENERATE_RESULT':
          this.toggleReGenerateResultModal()
          break
        case 'VIEW_RESULT':
          this.$router?.push({
            name: 'subject-result-view',
            params: {
              id: currentType.id,
            },
          })
          break
      }
    },

    async deleteType(id) {
      this.isDeleteInProgress = true
      let [response, error] = await this.deleteSubjectExamResult(id)
      this.isDeleteInProgress = false
      if (response) {
        this.toogleDeleteModel()
        this.filterRecords()
      }
    },
    toogleDeleteModel() {
      this.deleteModelShow = !this.deleteModelShow
      if (!this.deleteModelShow) this.currentSelectedResult = null
    },
    toggleGenerateResultModal() {
      this.generteResultModal = !this.generteResultModal
      if (!this.generteResultModal) this.currentSelectedResult = null
    },
    toggleReGenerateResultModal() {
      this.reGenerteResultModal = !this.reGenerteResultModal
      if (!this.reGenerteResultModal) this.currentSelectedResult = null
    },
    async generateResultForSubject() {
      let id = this.currentSelectedResult?.id
      let [response, error] = await this.generateSubjectResult(id)
      if (response) {
        this.toggleGenerateResultModal()
        this.filterRecords()
        this.$store.commit(
          'toast/NEW',
          { message: response.data.message, type: 'success' },
          { root: true },
        )
      }
    },
    async reGenerateResultForSubject() {
      let id = this.currentSelectedResult?.id
      let [response, error] = await this.reGenerateSubjectResult(id)
      if (response) {
        this.toggleReGenerateResultModal()
        this.filterRecords()
        this.$store.commit(
          'toast/NEW',
          { message: response.data.message, type: 'success' },
          { root: true },
        )
      }
    },
    redirectToDetailsPage(id) {
      this.$router?.push({
        name: 'subject-result-detail',
        params: {
          id: id,
        },
      })
    },
    setRightbarData(count = 0) {
      const rightBarContent = {
        header: {
          title: 'SUBJ_EXAM_RES',
          buttons: [
            {
              title: 'ADD_SUBJECT_EXAM_RESULT',
              classes: ['primary-button-right'],
              action: {
                func: () => {
                  this.toggleSubjectExamModal()
                },
              },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'TOTAL_SUBJECT_RESULT', value: count }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('exams', [
      'getSectionSubjectResultList',
      'deleteSubjectExamResult',
      'generateSubjectResult',
      'reGenerateSubjectResult',
    ]),
  },
}
</script>

<style lang="scss" module scoped>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
</style>
